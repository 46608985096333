<template>
  <div class="container h-100 th-px-24-md">
    <div
      class="d-flex align-items-center justify-content-center flex-column h-100 th-py-12 th-py-48-md"
    >
      <router-view :on-submit="onSubmit" />
    </div>
  </div>
</template>

<script>
import { nextQuestionType } from "../../utils/service/Questionnaire";

export default {
  name: "PublicQuestionnaireDataUsagePurpose",
  created() {
    this.$route.params.onboarding = true;
  },
  methods: {
    onSubmit() {
      if (this.$route.name === "public_questionnaire_data_usage_purpose") {
        this.$route.params.onboarding = false;
        return this.$router.push({
          name: "public_questionnaire",
          params: {
            ...this.$route.params,
            type: nextQuestionType("data_usage_purpose"),
          },
        });
      }

      return this.$router.push({
        name: "public_questionnaire_data_usage_purpose",
        params: {
          ...this.$route.params,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
