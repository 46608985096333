<template>
  <div>
    <div class="th-ml-16-minus" v-if="to">
      <v-btn text color="secondaryDark" @click="back">
        <v-icon size="16" color="secondaryDark" class="th-mr-8">
          mdi-arrow-left
        </v-icon>
        <slot name="back">
          {{ $translation.t("Back") }}
        </slot>
      </v-btn>
    </div>
    <h5 class="text-xl-center th-mt-36-md-minus th-mb-0" v-if="$slots.default">
      <slot />
    </h5>
  </div>
</template>

<script>
export default {
  name: "HeaderWithBack",
  props: {
    to: {
      type: Object,
      default: null,
    },
  },
  methods: {
    back() {
      this.$router.push(this.to);
    },
  },
};
</script>
