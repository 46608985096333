<template>
  <div class="container h-100 th-px-24-md">
    <header-with-back :to="{ name: 'home', params: $route.params }">
      {{ $t(`product.step.data_usage_purpose.title`) }}
    </header-with-back>
    <div
      class="d-flex align-items-center justify-content-center flex-column h-100 th-py-12 th-py-48-md"
    >
      <router-view :on-submit="onSubmit" />
    </div>
  </div>
</template>

<script>
import HeaderWithBack from "../../../components/Project/HeaderWithBack";

export default {
  name: "QuestionnaireDataUsagePurpose",
  components: {
    HeaderWithBack,
  },
  methods: {
    onSubmit() {
      if (this.$route.name === "questionnaire_type_data_usage_purpose") {
        return this.$router.push({
          name: "home",
          params: {
            ...this.$route.params,
          },
        });
      }

      return this.$router.push({
        name: "questionnaire_type_data_usage_purpose",
        params: {
          ...this.$route.params,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
